const ru = {
  intro: "Изменить рабочее пространство",
  form: {
    nameLabel: "Название пространства",
    cancelButton: "Отмена",
    editButton: "Сохранить"
  }
};

export default ru;
