const ru = {
  title: "<b>Распределение дохода <br>по тегам</b>",
  titleShort: "<b>По тегам</b>",
  otherCategory: "Остальные",
  empty: "<Не указаны>",
  noData:
    "У вас нет достаточного количества данных для отображения " +
    'графика "Распределение дохода по тегам"'
};

export default ru;
