const ru = {
  intro: "Перед тем как продолжить, нужно выбрать рабочее пространство",
  form: {
    workspaceIdLabel: "Рабочее пространство",
    workspaceIdPlaceholder: "Пожалуйста выберите...",
    selectButton: "Выбрать"
  }
};

export default ru;
