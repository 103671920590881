const en = {
  intro: "Before continuing, you need to select workspace",
  form: {
    workspaceIdLabel: "Workspace",
    workspaceIdPlaceholder: "Please select...",
    selectButton: "Select"
  }
};

export default en;
