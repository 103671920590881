const en = {
  intro: "Edit workspace",
  form: {
    nameLabel: "Workspace name",
    cancelButton: "Cancel",
    editButton: "Save"
  }
};

export default en;
