const ru = {
  documentTitle: "Политика конфиденциальности",
  text:
    "<h2 class='uk-heading-small uk-text-center'>Политика конфиденциальности</h2>" +
    "<p class='uk-text-center'>Последнее обновление: 19 февраля, 2020</p>" +
    "<h2 class='uk-text-lead uk-text-center'>Краткая версия</h2>" +
    "<p>Я не храню и не обрабатываю Ваши персональные данные на серверах, контроллируемых мной. Все персональные данные " +
    "обрабатываются непосредственно в браузере и хранятся на вашем Google Drive.</p>" +
    "<h2 class='uk-text-lead uk-text-center'>Полная версия</h2>" +
    '<p>Данная политика определяет как Я, Геннадий Догаев (в дальнейшем <b>"я"</b>) собираю, ' +
    'использую, храню и раскрываю информацию, полученную от пользователей (в дальнейшем <b>"Пользователь"</b>, ' +
    '<b>Вы</b>) веб-сайта <b>timesheet.gennady.pp.ua</b> (<b>"Сайт"</b>, <b>"Timesheet"</b>).</p>' +
    "<p>Эта политика применима к:</p>" +
    "<ul>веб-сайту <b>timesheet.gennady.pp.ua</b></ul>" +
    "<p>Используя Сайт, вы принимаете и соглашаетесь с данной политикой</p>" +
    "<h2>Контактная информация</h2>" +
    "<p>Вы можете адресовать вопросы касательно данной политики по адресу " +
    "<b>gendelflg@gmail.com</b></p>" +
    "<h2>Данные, которые я собираю</h2>" +
    "<p>Я смогу собирать следующие виды данных:</p>" +
    "<ol>" +
    "<li><b>Данные, которые Вы сами предоставляете</b>. Во время использования Сайта Вы можете предоставлять различные " +
    "данные путём заполнения онлайн-форм на Сайте. Эти данные включают описание активностей, которые Вы собираетесь отслеживать" +
    "при помощи Timesheet, а также затраченное на такие активности время</li>" +
    "<li><b>Информация от Google OAuth API</b>. Во время аутентификации, " +
    "Google OAuth API предоставляет Сайту Dfie персональную информацию. Такая информация включает в себя имя, адрес " +
    "электронной почты, уникальный идентификатор и изображение профиля</li>" +
    "<li><b>Данные из Таблиц Google Spreadsheets, созданых или открытых в Timesheet</b>. Это данные, которые Вы " +
    "предоставляли ранее путем заполнения онлайн-форм на сайте</li>" +
    "<li><b>Техническая информация</b>. Когда Вы используете Сайт, я сохраняю такую информацию как URL посещенных " +
    "страниц, информацию об ошибках, длительность визита, IP-адрес устройства и информацию об используемом браузере. " +
    "Данная информация собирается автоматически веб-сервером и не содержит персональных данных</li>" +
    "</ol>" +
    "<h2>Как я использую данные</h2>" +
    "<p>Данные, которые Вы сами предоставляете, Информацию от Google OAuth API и данные из Таблиц Google Spreadsheets " +
    "я использую исключительно для обеспечения функционирования Сайта. Я не храню такие данные на контроллируемых " +
    "мною серверах</p>" +
    "<p>Техническая информация сохраняется в логах сервера и используется мной для анализа количества пользователей, " +
    "регионов проживания и используемых устройств</p>"
};

export default ru;
