export default {
  any: {
    empty: '"{{label}}" не может быть пустым',
    required: 'Поле "{{label}}" Обязательно для заполнения'
  },
  string: {
    min: 'Значение поля "{{label}}" не может быть короче чем {{limit}} символов'
  },
  number: {
    base: 'Значение поля "{{label}}" должно быть числом',
    min: 'Значение поля "{{label}}" должно быть не меньше чем {{limit}}'
  },
  date: {
    min: 'Значение поля "{{label}}" должно быть больше или равно {{limit}}',
    isoDate: 'Значение поля "{{label}}" должно быть датой в формате ISO 8601'
  },
  array: {
    unique:
      'Содержимое поля "{{label}}" не должно содержать дублирующих значений'
  }
};
