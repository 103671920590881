const en = {
  intro: "Create new workspace",
  form: {
    nameLabel: "Workspace name",
    cancelButton: "Cancel",
    createButton: "Create"
  }
};

export default en;
