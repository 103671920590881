const en = {
  title: "<b>Earnings distribution <br>by tags</b>",
  titleShort: "<b>By tags</b>",
  otherCategory: "Rest",
  empty: "<Not specified>",
  noData:
    'You don\'t have enough data to display "Earnings distribution by tags" chart'
};

export default en;
