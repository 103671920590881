const ru = {
  legendTitle: "<b>Проект</b>",
  empty: "&lt;Не указан&gt;",
  yTitle: "Записанное время, в часах",
  title_day: "Распределение времени по дням и проектам",
  xTitle_day: "День",
  total_day: "Общее время за день",
  title_week: "Распределение времени по неделям и проектам",
  xTitle_week: "Неделя",
  total_week: "Общее время за неделю",
  title_month: "Распределение времени по месяцам и проектам",
  xTitle_month: "Месяц",
  total_month: "Общее время за месяц",
  title_year: "Распределение времени по годам и проектам",
  xTitle_year: "Год",
  total_year: "Общее время за год",
  noData:
    "У вас нет достаточного количества данных для отображения " +
    'графика "Распределение времени по дням и проектам"'
};

export default ru;
