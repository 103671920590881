const ru = {
  legendTitle: "<b>Проект</b>",
  empty: "&lt;Не указан&gt;",
  yTitle: "Записанный доход",
  title_day: "Распределение дохода по дням и проектам",
  xTitle_day: "День",
  total_day: "Общий доход за день",
  title_week: "Распределение дохода по неделям и проектам",
  xTitle_week: "Неделя",
  total_week: "Общий доход за неделю",
  title_month: "Распределение дохода по месяцам и проектам",
  xTitle_month: "Месяц",
  total_month: "Общий доход за месяц",
  title_year: "Распределение дохода по годам и проектам",
  xTitle_year: "Год",
  total_year: "Общий доход за год",
  noData:
    "У вас нет достаточного количества данных для отображения " +
    'графика "Распределение дохода по дням и проектам"'
};

export default ru;
