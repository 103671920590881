const en = {
  documentTitle: "About",
  header: "Track and analyze time for free",
  text:
    "Timesheet allows you to track your working time and earnings and analyze them. " +
    "Software is free and data is stored on Google Drive so you have full control of it",
  ctaButton: "Begin"
};

export default en;
