const ru = {
  title: "<b>Распределение дохода <br>по проектам</b>",
  titleShort: "<b>По проектам</b>",
  otherCategory: "Остальные",
  empty: "<Не указан>",
  noData:
    "У вас нет достаточного количества данных для отображения " +
    'графика "Распределение дохода по проектам"'
};

export default ru;
