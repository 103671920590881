const ru = {
  intro: "Создать новое рабочее пространство",
  form: {
    nameLabel: "Название пространства",
    cancelButton: "Отмена",
    createButton: "Создать"
  }
};

export default ru;
